// This is the scss entry file
import "../styles/index.scss";

// debugger
// import * as bootstrap from 'bootstrap';
// import * as bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/js/index.esm.js'

// We can import other JS file as we like
// import Jumbotron from "../components/jumbotron";

window.document.addEventListener("DOMContentLoaded", function () {
  window.console.log("dom ready");
});

